import MarketResearch from '@screens/market-research'
import { graphql } from 'gatsby'
import React from 'react'

const MarketResearchPage = ({ data }) => <MarketResearch data={data} />

export default MarketResearchPage
export const query = graphql`
  query MarketResearchQuery {
    allContentfulAutomatedReport {
      edges {
        node {
          id
          title
          content {
            raw
          }
          description
          slug
          coverImage {
            gatsbyImageData(width: 500, placeholder: BLURRED)
            file {
              url
            }
            title
          }
          categories {
            name
          }
        }
      }
    }
  }
`
