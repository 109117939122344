import BaseContainer from '@components/base-container'
import MarketResearchCard from '@components/report-card'
import { Color } from '@services/styles'
import MarketResearchImage from '@static/images/market-research/market-research.png'
import get from 'lodash/get'
import React, { useState } from 'react'
import { Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap'
import { FaSearch } from 'react-icons/all'
import './style.scss'

const MarketResearch = ({ data }) => {
  const [searchText, setSearchText] = useState()
  const reports = get(data, 'allContentfulAutomatedReport.edges').map(
    ({ node }) => node
  )

  const filteredReports =
    (searchText &&
      reports.filter(
        i =>
          i?.title?.toLowerCase().includes(searchText) ||
          i?.description?.toLowerCase().includes(searchText)
      )) ||
    reports

  const onClick = e => {
    if (
      e?.target?.className?.includes('get-started') ||
      e?.parentNode?.target?.className?.includes('get-started')
    ) {
      e?.stopPropagation()
    }
  }
  const headerHeight = 380
  return (
    <BaseContainer withPadding>
      <section className={'header-background'}>
        <div className={'curve-background d-none d-lg-block'}>
          <svg
            width={'100vw'}
            height={headerHeight}
            viewBox={`0 0 1440 524`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path
              d="M0 0H1440V176C1440 368.195 1284.2 524 1092 524H0V0Z"
              fill="#1E2D4F"
            />
          </svg>
        </div>
        <Container className="header-content-wrapper">
          <section>
            <Row className="header-container">
              <Col
                lg={{ span: 6 }}
                md={{ span: 12 }}
                className="d-flex align-items-start justify-content-center flex-column"
              >
                <h1 className={'text-white'}>
                  <strong>Market Research</strong>
                </h1>
                <p className={'text-white mb-5 mb-lg-0'}>
                  Our automated market research solutions are changing the way
                  companies approach market research. We leverage advanced
                  algorithms and machine learning to give you better solutions
                  at more affordable prices.
                </p>
              </Col>
              <Col
                lg={{ span: 4, offset: 1 }}
                md={{ span: 9 }}
                className="d-flex align-items-start justify-content-center flex-column d-none d-lg-block"
              >
                <img
                  src={MarketResearchImage}
                  style={{
                    width: '100%',
                    maxHeight: '80%',
                    objectFit: 'contain'
                  }}
                />
              </Col>
            </Row>
          </section>
          <section
            className={'position-absolute search-wrapper'}
            style={{ bottom: -25 }}
          >
            <InputGroup
              onChange={(e: any) => setSearchText(e?.target?.value)}
              className={'rounded-3'}
            >
              <FormControl
                size="lg"
                placeholder="Search for a research report"
                aria-label="search for a research report"
                id="input-search-row"
                style={{ borderRight: 'none', fontSize: 18 }}
              />
              <InputGroup.Text
                style={{ backgroundColor: Color.WHITE, padding: '0.375rem' }}
              >
                <div
                  className={'rounded-3'}
                  style={{
                    backgroundColor: Color.PRIMARY,
                    padding: '0.375rem 0.5rem',
                    color: Color.WHITE
                  }}
                >
                  <FaSearch size={20} />
                </div>
              </InputGroup.Text>
            </InputGroup>
          </section>
        </Container>
      </section>

      <Container className="my-5">
        <Row>
          {filteredReports.map(data => (
            <Col xl={3} lg={4} md={6} sm={12} xs={12} className={'py-2'}>
              <MarketResearchCard
                key={data.id}
                location="/market-research/"
                data={data}
                onClick={onClick}
                isRelated={false}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </BaseContainer>
  )
}

export default MarketResearch
